import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { routeAnimation } from './animations/router.animation';
import { Meta, Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    routeAnimation,
  ],
})
export class AppComponent implements OnDestroy {
  @ViewChild('anchor', {read: ElementRef, static: true}) anchor: ElementRef;

  private readonly routerEvent;
  private isFirstTime = true;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private title: Title, private meta: Meta) {
    this.routerEvent = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        const child = this.activatedRoute.firstChild;
        return child.snapshot.data || {};
      }),
    ).subscribe(({title, description}) => {
      this.title.setTitle(title);
      this.meta.updateTag({name: 'description', content: description});
      this.meta.updateTag({property: 'og:title', content: title});
      this.meta.updateTag({property: 'og:description', content: description});
      this.scrollToContent();
    });
  }

  ngOnDestroy(): void {
    if (this.routerEvent) {
      this.routerEvent.unsubscribe();
    }
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  private scrollToContent() {
    if (this.isFirstTime) {
      this.isFirstTime = false;
    } else {
      this.anchor.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  }
}
