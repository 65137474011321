import { animate, query, style, transition, trigger } from '@angular/animations';

export const routeAnimation = trigger('routeAnimation', [
  transition('* => *', [
    query(
      ':enter',
      [style({left: '-50%', opacity: 0})],
      {optional: true},
    ),
    query(
      ':leave',
      [style({left: 0, opacity: 1}), animate('.2s', style({left: '-50%', opacity: 0}))],
      {optional: true},
    ),
    query(
      ':enter',
      [style({left: '-50%', opacity: 0}), animate('.2s', style({left: 0, opacity: 1}))],
      {optional: true},
    ),
  ]),
]);
