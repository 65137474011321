import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-list-skills',
  templateUrl: './list-skills.component.html',
  styleUrls: ['./list-skills.component.scss'],
})
export class ListSkillsComponent {
  @Input() icon = '';
  @Input() title = '';
  @Input() skills = [];
}
