import {Component} from '@angular/core';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss'],
})
export class ResumeComponent {
  languages = ['PHP', 'ES6', 'CSS', 'HTML', 'Typescript'];
  frameworks = ['Symfony', 'VueJS', 'NuxtJS', 'Angular', 'Jquery', 'Bootstrap'];
  oses = ['Windows', 'Centos', 'Ubuntu'];
  databases = ['MongoDB', 'MariaDB', 'MySQL'];
  managements = ['Team Management', 'Code Review', 'Training', 'Analyzing Project'];
  others = [
    'Leadership', 'Research', 'Docker', 'Micro Service', 'GraphQL', 'Restful',
    'Jenkins', 'CI/CD', 'Git', 'Python', 'Django', 'MAC', 'Deployment', 'Facebook API',
    'Google API', 'RabbitMQ', 'Photoshop', 'Technical Consulting',
  ];
}
