import { Component } from '@angular/core';
import { Testimonial } from '../../models/testimonial.model';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss'],
})
export class TestimonialComponent {
  testimonials = [
    new Testimonial({
      name: 'Yen Le',
      avatar: 'assets/images/testimonials/yen-le.jpg',
      job: 'HR',
      comment: `<p>He's quick in action & responsible with his works.
He not only comes to work by passion but also sharing & caring spirit.</p>`,
      linkedin: 'https://www.linkedin.com/in/hylh/',
    }),
    new Testimonial({
      name: 'Nam Phuong Nguyen Luong',
      avatar: 'assets/images/testimonials/nguyen-luong-nam-phuong.jpg',
      job: 'Community Engager',
      comment: `<p>Hữu Bình, beside jokes, for me, you are the one who doesn't stop learning, and continue to push your limit far.
You impress and inspire me a lot. So thank you for what you contributed to Officience, and wish you have force,
courage to archieve this new challenge, and keep your smile always everywhere you go, especially with your lovely wife.</p>
Congratulations, you deserve it :)`,
      linkedin: 'https://www.linkedin.com/in/namphuongnl/',
    }),
    new Testimonial({
      name: 'Lê Sơn',
      avatar: 'assets/images/testimonials/le-hao-son.jpg',
      job: 'CTO at Rooxim Computer Science',
      comment: `<p>A geek that I could trust and give him my back on urgent cases.
I'm most impressed by his working atitude, kind of thing that I must keep trying to catch up him.</p>
<p>Beside technical, he is open-minded, great at comunicate (but a little hot tempered sometime),
quick adapt and dynamic when moving around and help/lead other guy in team.</p>
<p>Would definely suggest him to my friend when they want to find a great guy.</p>`,
      linkedin: 'https://www.linkedin.com/in/cloudle/',
    }),
    new Testimonial({
      name: 'Thao-Lan NGUYEN HOANG',
      avatar: 'assets/images/testimonials/nguyen-hoang-thao-lan.jpg',
      job: 'Actively looking for new challenges in Hi-tech and/or Management field',
      comment: `<p>A good developer with a strong basic knowledge. With the strong base, he takes on new challenge easily.</p>
<p>He has widened his competence so fast in the 2 recent years. A good asset in a team of mini to medium size (3-7 members).</p>
<p>Open-minded: so he merges in new technology and new team very easy.</p>
<p>Leadership mindset: he prefers do first, then lead the team by example.</p>
<p>If he can improve toward of a "Product Owner" mindset, he will be the best IT artist.</p>`,
      linkedin: 'https://www.linkedin.com/in/thaolannguyenhoang/',
    }),
    new Testimonial({
      name: 'Khoa Nguyễn Tấn',
      avatar: 'assets/images/testimonials/nguyen-tan-khoa.jpg',
      job: 'PHP Developer at Transcosmos Technologic Arts Vietnam',
      comment: `<p>Binh is a great fullstack developer. He knows a lot of the technologies from Backend to Frontend, DevOps skills.</p>
<p>I knew and worked with him over more 6 years. From he have just finished the university.
I was surprised by his rapid progress about software developing skills.</p>
<p>More than that, he is a responsible person in the work. In the team, he is friendly and interactive.
And he is simple and clear in mindset. It is great to work with him. :)</p>`,
      linkedin: 'https://www.linkedin.com/in/tankhoanguyen/',
    }),
    new Testimonial({
      name: 'Tung Nguyen',
      avatar: 'assets/images/testimonials/nguyen-vuong-tung.jpg',
      job: 'Frontend Developer',
      comment: `<p>Binh is ok, not a colleague but also a friend you can trust.
He's straight but can do various roles from Backend to Frontend, a real mercenary with range of weapons, tools and tricks.
He's can consult anything related to technology. I give him 5/7 for best supporter.</p>`,
      linkedin: 'https://www.linkedin.com/in/tung-gunt/',
    }),
    new Testimonial({
      name: 'Thong Pham',
      avatar: 'assets/images/testimonials/pham-vu-thanh-thong.jpg',
      job: 'Software Engineer at Mingle VietNam',
      comment: `<p>I have chance to work with him 2 years ago at Officence as Mobile Developer.</p>
<p>He's very nice and support me in some first days at Officence.
We can easily to discuss about projects and issues with appropriated solutions by Win-Win way.</p>
<p>The strongest point from him is the learning fast. but it's also the weak point because sometime he wasn't clever in coding.
Totally, BinhNguyen is smart and humble developer.</p>`,
      linkedin: 'https://www.linkedin.com/in/phamvuthanhthong/',
    }),
    new Testimonial({
      name: 'Zoom NGUYỄN',
      avatar: 'assets/images/testimonials/zoom.jpg',
      job: 'Software Program Manager at Fossil Group, Inc.',
      comment: `<p>Extremely effective! This is the term that comes to mind to describe Binh.</p>
<p>I had the pleasure of working with Binh for 2 years in the Officience Company SARL and have worked on several major projects
Binh be an asset to any team. Any employer would be glad to have a leader like Binh</p>`,
      linkedin: 'https://www.linkedin.com/in/zoomnguyen2204/',
    }),
  ];
}
