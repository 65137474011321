import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TestimonialComponent} from './pages/testimonial/testimonial.component';
import {HomeComponent} from './pages/home/home.component';
import {ResumeComponent} from './pages/resume/resume.component';
import {ContactComponent} from './pages/contact/contact.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      title: 'HB Portfolio',
      description: 'Hi, I\'m HB, an dynamic, active, creation, enthusiastic, friendly technical leader. Nice to meet you!',
    },
  },
  {
    path: 'resume',
    component: ResumeComponent,
    data: {
      title: 'Resume | HB',
      description: 'The shorten resume of me, note that just a shorten resume ;)!',
    },
  },
  {
    path: 'testimonial',
    component: TestimonialComponent,
    data: {
      title: 'Testimonial | HB',
      description: 'Some comments of my colleagues, my friends! So interesting :)!',
    },
  },
  {
    path: 'contact',
    component: ContactComponent,
    data: {
      title: 'Contact | HB',
      description: 'Do you like me at first sight? Contact me now ^^!',
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
