export class Testimonial {
  private _name: string;
  private _avatar: string;
  private _job: string;
  private _comment: string;
  private _linkedin: string;

  constructor(obj = null) {
    if (obj) {
      this.name = obj.name || '';
      this.avatar = obj.avatar || '';
      this.job = obj.job || '';
      this.comment = obj.comment || '';
      this.linkedin = obj.linkedin || '';
    }
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get avatar(): string {
    return this._avatar;
  }

  set avatar(value: string) {
    this._avatar = value;
  }

  get job(): string {
    return this._job;
  }

  set job(value: string) {
    this._job = value;
  }

  get comment(): string {
    return this._comment;
  }

  set comment(value: string) {
    this._comment = value;
  }

  get linkedin(): string {
    return this._linkedin;
  }

  set linkedin(value: string) {
    this._linkedin = value;
  }
}

