import { Directive, ElementRef, OnInit } from '@angular/core';

declare const SimpleBar;

@Directive({
  selector: '[appSimplebar]',
})
export class SimplebarDirective implements OnInit {
  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    if (typeof window !== 'undefined') {
      new SimpleBar(this.elementRef.nativeElement, {autoHide: true});
    }
  }
}
